<template>
  <div :class="$style.order">
    <h2 :class="$style.order__title">
      Заказ {{ order.id }} <span>{{ arbitrationStatus }}</span>
    </h2>
    <div v-if="order.attributes" :class="$style.order__content">
      <div :class="$style.order__content_wrap">
        <div :class="[$style.order__content_info, $style.order__content_info_date]">
          <p>Начало занятия</p>
          <div>
            <span>{{
              order.datetime
                ? this.moment(order.datetime).format('DD-MM-YYYY  LT')
                : 'Не заполнено'
            }}</span>
          </div>
        </div>
        <div :class="$style.order__content_info">
          <p>Дата окончания заказа</p>
          <div>
            <span>{{
              this.order.datetime
                ? this.moment(order.datetime).add(1, 'minute').format('DD-MM-YYYY  LT')
                : 'Не заполнено'
            }}</span>
          </div>
        </div>

        <div :class="$style.order__content_info" v-if="!order.group">
          <p>Статус</p>
          <div>
            <span>{{ order.arbitration.status.name }}</span>
          </div>
        </div>

        <div :class="$style.order__content_info">
          <p>Тип занятия</p>
          <div>
            <span>{{
              `${
                !order.group
                  ? 'Школьное предметы'
                  : order.group.preschoolAttributes
                  ? 'Подготовка к школе'
                  : order.group.logopedisticAttributes
                  ? 'Логопедическое занятие'
                  : 'Не заполнено'
              }`
            }}</span>
          </div>
        </div>
        <div :class="$style.order__content_info" v-if="!order.group">
          <p>Предмет</p>
          <div>
            <span>{{ order.subject ? order.subject.name : 'Не заполнено' }}</span>
          </div>
        </div>

        <div :class="$style.order__content_info">
          <p>Класс/возраст</p>
          <div>
            <span>{{
              order.attributes && order.attributes.studentAge
                ? order.attributes.studentAge
                : order.attributes.studentGrade
            }}</span>
          </div>
        </div>
        <div :class="$style.order__content_info">
          <p>Длительность занятия</p>
          <div>
            <span>{{ order.duration.name }}</span>
          </div>
        </div>

        <div :class="$style.order__content_info">
          <p>Запрос</p>
          <div>
            <span>{{ order.attributes.lessonType.name }}</span>
          </div>
        </div>
        <div :class="$style.order__content_info">
          <p>Инициатор прекращения занятия</p>
          <div>
            <span>{{
              order.arbitration.initiatorId == order.teacher.id ? 'Педагог' : 'Ученик'
            }}</span>
          </div>
        </div>
        <div :class="$style.order__content_info">
          <p>Комментарий</p>
          <div>
            <span>{{
              order.arbitration.appeal ? order.arbitration.appeal.comment : 'Не заполнено'
            }}</span>
          </div>
        </div>
        <div :class="$style.order__content__records">
          <div :class="$style.order__content_info">
            <Button
              type="table"
              @click="lessonRecord(order.id, order.student.id, 'lesson')"
            >
              Запись занятия ученика
            </Button>
          </div>
          <div :class="$style.order__content_info">
            <Button
              type="table"
              @click="lessonRecord(order.id, order.teacher.id, 'lesson')"
            >
              Запись занятия педагога
            </Button>
          </div>
          <div :class="$style.order__content_info">
            <Button
              type="table"
              @click="lessonRecord(order.id, order.student.id, 'canvas')"
            >
              Запись доски ученика</Button
            >
          </div>
          <div :class="$style.order__content_info">
            <Button
              type="table"
              @click="lessonRecord(order.id, order.teacher.id, 'canvas')"
            >
              Запись доски педагога</Button
            >
          </div>
        </div>
        <div :class="$style.order__content_info">
          <Button type="table" :disabled="isCloseArbitration" @click="changeStatus(5)">
            Неправ педагог
          </Button>
        </div>
        <div :class="$style.order__content_info_arbitr">
          <div :class="$style.newOrder__teachers__header">
            <Input
              :class="$style.newOrder__teachers__header_input"
              placeholder="Ссылка на документ"
              v-model="expertConclusionLink"
            />
          </div>
        </div>

        <div>
          <div
            v-for="opinion in expertOpinins"
            :key="opinion.id"
            :class="$style.order__content_wrapper"
          >
            <InputRadio
              :value="expertOpinion === opinion.id"
              :disabled="isCloseArbitration"
              @input="setExpertOpinion(opinion.id)"
            />
            <span>{{ opinion.text }}</span>
          </div>
          <div :class="$style.order__content_wrapper_button">
            <Button
              type="table"
              :disabled="isCloseArbitration"
              @click="changeStatus(expertOpinion)"
            >
              сохранить / принять решение
            </Button>
          </div>
        </div>
      </div>
    </div>

    <br />

    <div v-if="order.attributes" :class="$style.order__table">
      <div :class="$style.header">
        <div :class="$style.header__column">id заказа</div>
        <div :class="$style.header__column">id педагога</div>
        <div :class="$style.header__column">ФИО педагога</div>

        <div :class="$style.header__column">телефон педагога</div>
        <div :class="$style.header__column">Чат</div>
      </div>
      <div :class="$style.order__teacherSelection">
        <div :class="$style.order__teacherSelection__wrapper">
          <div :class="$style.order__teacherSelection__item">
            {{ order.id }}
          </div>
          <div :class="$style.order__teacherSelection__item">
            {{ order.teacher.id }}
          </div>
          <div :class="$style.order__teacherSelection__item">
            {{
              order.teacher.attributes.lastName +
              ' ' +
              order.teacher.attributes.firstName +
              ' ' +
              order.teacher.attributes.middleName
            }}
          </div>
          <div :class="$style.order__teacherSelection__item">
            {{ order.teacher.phone }}
          </div>
          <div :class="$style.order__teacherSelection__item">
            <Button type="table" @click="createRoom(order.teacher.id)"> Открыть чат </Button>
          </div>
        </div>
        <div :class="$style.order__teacherSelection__wrapperLine">
          <div :class="$style.order__teacherSelection__line" />
          <div :class="$style.order__teacherSelection__line" />
          <div :class="$style.order__teacherSelection__line" />
          <div :class="$style.order__teacherSelection__line" />
          <div :class="$style.order__teacherSelection__line" />
        </div>
      </div>
    </div>
    <br />

    <div v-if="order.attributes" :class="$style.order__table">
      <div :class="$style.header">
        <div :class="$style.header__column">id ученика</div>
        <div :class="$style.header__column">ФИО ученика</div>
        <div :class="$style.header__column">ФИО родителя</div>
        <div :class="$style.header__column">id родителя</div>
        <div :class="$style.header__column">телефон родителя</div>
        <div :class="$style.header__column">Чат</div>
      </div>
      <div :class="$style.order__teacherSelection">
        <div :class="$style.order__teacherSelection__wrapper">
          <div :class="$style.order__teacherSelection__item">
            {{ order.student.id }}
          </div>
          <div :class="$style.order__teacherSelection__item">
            {{
              order.student.attributes.lastName +
              ' ' +
              order.student.attributes.firstName +
              ' ' +
              order.student.attributes.middleName
            }}
          </div>
          <div :class="$style.order__teacherSelection__item">
            {{
              order.customer.attributes.lastName +
              ' ' +
              order.customer.attributes.firstName +
              ' ' +
              order.customer.attributes.middleName
            }}
          </div>
          <div :class="$style.order__teacherSelection__item">
            {{ order.customer.id }}
          </div>
          <div :class="$style.order__teacherSelection__item">
            {{ order.customer.phone }}
          </div>

          <div :class="$style.order__teacherSelection__item">
            <Button type="table" @click="createRoom(order.customer.id)"> Открыть чат </Button>
          </div>
        </div>
        <div :class="$style.order__teacherSelection__wrapperLine">
          <div :class="$style.order__teacherSelection__line" />
          <div :class="$style.order__teacherSelection__line" />
          <div :class="$style.order__teacherSelection__line" />
          <div :class="$style.order__teacherSelection__line" />
          <div :class="$style.order__teacherSelection__line" />
          <div :class="$style.order__teacherSelection__line" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

import Moment from 'moment'

import orders from '@/api/orders'

import Button from '@/basic/Button'
import InputRadio from '@/basic/InputRadio'

import 'vue2-datepicker/index.css'

import { saveAs } from 'file-saver'

import Input from '@/basic/Input'

const expertOpinins = [
  {
    id: 7,
    text: 'Эксперт подтвердил жалобу(Педагог вел себя корректно)'
  },
  {
    id: 8,
    text: 'Эксперт подтвердил жалобу(Педагог вел себя некорректно)'
  },
  {
    id: 9,
    text: 'Эксперт отклонил жалобу'
  }
]

export default {
  components: {
    Button,
    Input,
    InputRadio
  },
  data() {
    return {
      teachers: {},
      datetime: null,
      text: '',
      order: [],
      expertConclusionLink: '',

      expertOpinins,
      expertOpinion: null
    }
  },
  async mounted() {
    const { id } = this.$route.params
    const { data } = await orders.getOrders({
      filter: {
        id
      }
    })
    const orderDetails = data.result.rows[0]

    this.datetime = this.moment(orderDetails.datetime).format('YYYY-MM-DD HH:mm:ss')
    this.order = data.result.rows[0]
    this.expertConclusionLink = this.order.arbitration.expertConclusionLink

    if (this.isCloseArbitration) {
      this.expertOpinion = this.order?.arbitration?.statusesHistory[2]?.statusId ?? null
    }
  },
  computed: {
    moment() {
      return Moment
    },
    isCloseArbitration() {
      return [2, 10, 11, 12].includes(this.order?.arbitration?.statusId)
    },
    arbitrationStatus() {
      return this.order?.arbitration?.status?.name ?? ''
    }
  },
  methods: {
    ...mapMutations(['setChat']),

    createRoom(event) {
      this.$socket.emit(
        'dashboard-chats-init',
        {
          userId: event
        },
        (data) => {
          //this.$router.push(`/chats/${data.result.id}`)
          this.chatId = data.result.id
          this.$socket.emit('dashboard-chats-enter', { chatId: this.chatId }, (data) => {
            if (data && data.success) {
              this.setChat(data.result)
              this.$router.push(`/chats/${this.chatId}`)
              //this.$router.push({ name: 'newChat', params: { myId: this.chatId } })
            }
          })
        }
      )
    },
    async lessonRecord(orderId, userId, type) {
      try {
        const data = await orders.lessonRecord(orderId, userId, type)

        const blob = new Blob([data.data], { type: 'application/zip' })
        saveAs(blob, 'data.zip')
      } catch (e) {
        alert('Запись отсутствует')
      }
    },
    async changeStatus(statusId) {
      const ask = () =>
        this.$store.dispatch('openModal', [
          'Confirm',
          {
            title: 'Закрытие арбитража',
            message: `Вы выбрали "${
              this.expertOpinins.find((o) => o.id === this.expertOpinion).text
            }". Продолжить?`
          }
        ])

      if (!(await ask())) {
        return
      }

      try {
        await orders.changeStatus(statusId, this.expertConclusionLink, this.order.id)

        const { id } = this.$route.params
        const { data } = await orders.getOrders({
          filter: {
            id
          }
        })
        const orderDetails = data.result.rows[0]

        this.datetime = this.moment(orderDetails.datetime).format('YYYY-MM-DD HH:mm:ss')
        this.order = data.result.rows[0]
      } catch (error) {
        console.warn(error) // eslint-disable-line

        this.$store.commit(
          'setNoticeData',
          `<p style="margin: 2rem 3rem; font-size: 14px">Ошибка закрытия арбитража по заказу ID ${this.order?.id}!</p>`
        )

        this.$store.commit('setNotice', 'ErrorNotice')

        setTimeout(() => {
          this.$store.commit('setNoticeData', null)
          this.$store.commit('setNotice', null)
        }, 4000)
      }
    },
    setExpertOpinion(opinion) {
      if (this.expertOpinion === opinion) {
        this.expertOpinion = null
      } else {
        this.expertOpinion = opinion
      }
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';
.order {
  &__title {
    font-size: 2.25rem;
    font-weight: 500;
    margin-bottom: 30px;

    span {
      margin: 0 1rem;
      font-size: 1.25rem;
      color: $wet-asphalt;
    }
  }
  &__header {
    display: flex;
    margin: 0 0 2rem;
  }

  &__input {
    width: 15rem;
    margin: 0 4rem 0 0;
  }
  &__content {
    &_wrap {
      display: flex;
      flex-wrap: wrap;
    }
    &__records {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin: 1rem 0 0;
    }
    &_info {
      position: relative;
      min-width: 275px;
      width: auto;
      margin-right: 10px;
      margin-bottom: 15px;
      &_arbitr {
        display: flex;
        width: 100%;
        margin-bottom: 15px;
        div {
          width: 100%;
          margin: 0;
        }
      }
      p {
        position: absolute;
        top: -8px;
        left: 5px;
        background: #fff;
        font-size: 12px;
      }
      div {
        border: 1px solid black;
        padding: 5px;
      }
      &_date {
        div {
          border: none;
        }
        border: 1px solid black;
        i {
          right: 15px;
        }
      }
    }
    &_wrapper {
      display: flex;
      align-items: center;

      &_button {
        margin: 1rem 0;
      }
    }
  }
  &__form {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    width: 26.25rem;
    margin: 0 1.25rem 1.25rem 0;
  }

  &__subtitle {
    margin: 1rem 0;
    font-size: 0.95rem;
    display: flex;
    justify-content: space-between;
    button {
      padding: 10px 15px;
      max-width: 300px;
      height: 36px;
    }
  }

  &__table {
    width: 100%;
    border: 1px solid $table-border;

    .header {
      display: flex;
      justify-content: space-between;
      height: 2.5rem;
      border-bottom: 1px solid $table-border;

      &__column {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;
        border-right: 1px solid $table-border;
        font-size: 1.1rem;
        font-weight: 500;

        &:first-child {
          width: 10%;
        }

        &:nth-child(2) {
          width: 20%;
        }

        &:nth-child(3) {
          width: 20%;
        }

        &:nth-child(4) {
          width: 15%;
        }

        &:nth-child(n + 5) {
          width: 15%;
        }

        &:nth-child(n + 6) {
          width: 20%;
        }

        &:last-child {
          border: none;
        }
      }
    }

    .order__teacherSelection {
      position: relative;
      display: flex;
      flex-direction: column;
      height: 91%;

      &__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 2.5rem;
        border-bottom: 1px solid $table-border;
      }

      &__item {
        margin-right: 10px;
        display: flex;
        align-items: center;
        padding: 0.7rem 1rem;
        font-size: 0.875rem;
        font-weight: 500;
        &__buttom {
          font-size: 1rem;
        }
        &:first-child {
          width: 10%;
        }

        &:nth-child(2) {
          width: 20%;
        }

        &:nth-child(3) {
          width: 20%;
        }

        &:nth-child(4) {
          width: 15%;
        }

        &:nth-child(n + 5) {
          width: 15%;
        }

        &:nth-child(n + 6) {
          width: 20%;
        }

        &:last-child {
          border: none;
        }

        &_active {
          background: $red;
          border-right: 1px solid $table-border;
        }
      }

      &__wrapperLine {
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 100%;
      }

      &__line {
        border-right: 1px solid $table-border;

        &:first-child {
          width: 10%;
        }

        &:nth-child(2) {
          width: 20%;
        }

        &:nth-child(3) {
          width: 20%;
        }

        &:nth-child(4) {
          width: 15%;
        }

        &:nth-child(n + 5) {
          width: 15%;
        }

        &:nth-child(n + 6) {
          width: 20%;
        }

        &:last-child {
          border: none;
        }
      }
    }
  }

  &__position_button {
    display: flex;
    justify-content: flex-end;
  }

  &__button {
    width: 26.25rem;
    margin: 3.375rem 0 0;
  }
}
</style>
